@import url("https://fonts.googleapis.com/css?family=Inter");

body {
  margin: 0;
  font-family: "Inter", -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto",
    "Oxygen", "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  /* display: none; <- Crashes Chrome on hover */
  -webkit-appearance: none;
  margin: 0; /* <-- Apparently some margin are still there even though it's hidden */
}

.css-1f8rduv-MuiButtonBase-root-MuiChip-root {
  background-color: #3c96ef !important;
}

.css-1qn27m4-MuiButtonBase-root-MuiChip-root.MuiChip-clickable:hover {
  background-color: white !important;
}

.my-swal {
  z-index: 10000;
}

.new-payment-date {
  width: 100%;
}

.delete-store,
.send-store-token {
  font-size: 1.125rem;
  margin-top: 1rem;
}

.delete-store-confirm,
.send-store-token-confirm {
  width: 13rem;
}

.delete-store-cancel,
.send-store-token-cancel {
  width: 13rem;
  background-color: white !important;
  color: #ff7e2d !important;
  border: 1px solid var(--primary-states-outlined-resting-border, #ff7e2d) !important;
}

.disposition-status > div {
  color: #ff7e2d !important;
}

.uploaded-invoice-confirm {
  width: 25rem;
  border: 1px solid #ff7e2d !important;
  background-color: white !important;
  color: #ff7e2d !important;
}

@media (max-width: 37.5rem) {
  .uploaded-invoice-confirm {
    width: 10rem;
  }
}

@media (min-width: 37.5rem) {
  .init-dashboard {
    height: inherit;
  }
}
